import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { addCommas, removeCommas } from '../../utils/helper';
const SliderWithTooltip = createSliderWithTooltip(Slider);

let SliderElement = ({ itemVals, item, idx, setVals, vals }) => {
    let formatDollars = (num) => '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

    let mustBeSlider = item === "paymentFrequency" && itemVals && itemVals.config && itemVals.config.marks && itemVals.value !== undefined && itemVals.config.marks[itemVals.value] ? true : false;
    let sliderValue = mustBeSlider ? 
                        itemVals.config.marks[itemVals.value] :
                        itemVals.value;
    let sliderValueWithSymbol = item === "paymentFrequency" && itemVals && itemVals.config && itemVals.config.marks && itemVals.value !== undefined && itemVals.config.marks[itemVals.value] ? 
                                sliderValue : 
                                `${itemVals.type === 'amt' ? formatDollars(sliderValue) : sliderValue}${itemVals.type === 'perc' ? '%' : ""}${itemVals.type === 'years' ? ' yrs' : ""}`;
    let setterFn = (value) => setVals({ ...vals, [item]: { ...vals[item], value } });
    return (
        <div className="slider-item" key={idx}>
            <label className="primary-color uppercase" htmlFor={item}>{itemVals.label}</label>
            <input type="text" value={addCommas(sliderValue)} className={`slider-value-input ${mustBeSlider ? 'none' : 'mobile-only'}`} onChange={(e) => setterFn(removeCommas(e.target.value))} />
            <p className="desktop-only">{sliderValueWithSymbol}</p>
            <div className={mustBeSlider ? "" : "desktop-only"}>
                <SliderWithTooltip
                    tipProps={{ placement: 'top', prefixCls: 'rc-slider-tooltip' }}
                    onChange={setterFn}
                    id={item}
                    name={item} 
                    {...itemVals.config}
                >
                </SliderWithTooltip>
            </div>
        </div>
    )
}

export default SliderElement
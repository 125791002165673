import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import SliderElement from './SliderElement';
import { addCommas } from '../../utils/helper';
import { etoCalcValues } from '../../data/calculators';

const ETOCalculator = () => {
    // const [currMortgage, setCurrMortgage] = useState("80000");
    // const [propertyValue, setPropertyValue] = useState("100000");
    const [vals, setVals] = useState(etoCalcValues);
    
    let calc = () => {
        let { propertyValue, currMortgage } = vals;

        // convert to floats
        let currMortgageVal = +currMortgage.value;
        let propertyValueVal = +propertyValue.value;

        let total = (propertyValueVal * 0.95) - currMortgageVal;
        return (
            <div className="results">
                <div className="result">
                    <span className="result-label">You can access up to</span>
                    <span className="result-value">${addCommas(total.toFixed(2))}</span>
                </div>
            </div>
        )
    }

    // console.log('vals', vals);
    return (
        <div>
            <form id="mortgage-payment-calculator">
                {/* <div className="slider-item">
                    <label className="primary-color uppercase" htmlFor="propertyValue">Your Property Value</label>
                    <input type="text" value={addCommas(propertyValue)} id="propertyValue" className={`slider-value-input`} onChange={(e) => setPropertyValue(removeCommas(e.target.value))} />
                </div>
                <div className="slider-item">
                    <label className="primary-color uppercase" htmlFor="currMortgage">Current Mortgage</label>
                    <input type="text" value={addCommas(currMortgage)} id="currMortgage" className={`slider-value-input`} onChange={(e) => setCurrMortgage(removeCommas(e.target.value))} />
                </div> */}
                {
                    Object.keys(vals).map((item, idx) => (
                        <SliderElement key={idx} itemVals={vals[item]} item={item} vals={vals} setVals={setVals} />
                    ))
                }
                {calc()}
            </form>
        </div>
    )
}

export default ETOCalculator;
import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import SliderElement from './SliderElement';
import { addCommas } from '../../utils/helper';
import { mortgageCalcValues } from '../../data/calculators';

const MortgagePaymentCalculator = ({match}) => {
    const [vals, setVals] = useState(mortgageCalcValues);
    let pmt = (ir, np, pv, fv = 0, type = 0) => {
        var pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);
    
        if (ir === 0)
            return -(pv + fv)/np;
    
        pvif = Math.pow(1 + ir, np);
        pmt = - ir * pv * (pvif + fv) / (pvif - 1);
    
        if (type === 1)
            pmt /= (1 + ir);
    
        return pmt;     
    } 

    let calc = () => {
        // console.log('values', vals);
        let { purchase, downpayment,  interestRate, paymentFrequency, amortization } = vals;
        
        let interest = interestRate.value/100,
            compound = 2;
        // console.log(interest)
        let mortgage = purchase.value - downpayment.value;
        let paymentPerYear = paymentFrequency.value === 0 ? 12 : 
                             paymentFrequency.value === 2 ? 24 :
                             paymentFrequency.value === 4 ? 26 :
                             paymentFrequency.value === 6 ? 52 : 0;
        let effInterestRate = Math.pow(( 1 + interest / compound), (compound / paymentPerYear)) - 1;

        // let payment = ((mortgage * (interest/12)) / (1 - Math.pow((1 + (interest/12)), (-1 * amortization.value * 12)) ));
        let payment = -1 * pmt(effInterestRate, amortization.value * paymentPerYear, mortgage);
        // console.log('eff', effInterestRate)
        return (
            <div className="results">
                <div className="result">
                    <span className="result-label">Mortgage Amount:</span>
                    <span className="result-value">${addCommas(mortgage)}</span>
                </div>
                <div className="result">
                    <span className="result-label">Payment</span>
                    <span className="result-value">${addCommas(payment.toFixed(2))}</span>
                </div>
            </div>
        )
    }

    // console.log('vals', vals);
    return (
        <div>
            <form id="mortgage-payment-calculator">
                {
                    Object.keys(vals).map((item, idx) => (
                        <SliderElement key={idx} itemVals={vals[item]} item={item} vals={vals} setVals={setVals} />
                    ))
                }
                {calc()}
            </form>
        </div>
    )
}

export default MortgagePaymentCalculator;
import React, { useState } from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import MortgagePaymentCalculator from "../../components/Calculators/MortgagePaymentCalculator"
import AffordabilityCalculator from "../../components/Calculators/AffordabilityCalculator"
import ETOCalculator from "../../components/Calculators/ETOCalculator"
import PageTransitionContainer from "../../components/PageTransitionContainer"
import { Link } from "gatsby"
import "../../styles/pages/calculators.css"

const Index = (props) => {
  const [calc, switchCalc] = useState("eto")
  return (
    <PageTransitionContainer>
      <Layout location={props.location} page="home">
        <Seo
          title="Mortgage Affordability ETO Calculators | RateLock"
          location={props.location}
        />
        <section id="calculators">
          <h1 className="page-heading">Calculators</h1>
          <div id="calc-grid">
            <div id="calcName-container">
              <div
                className={`${calc === "eto" ? "selected" : ""}`}
                onClick={() => switchCalc("eto")}
                onKeyDown={() => switchCalc("eto")}
                tabIndex={0}
                role="button"
                aria-label="Refinance and Equity Take Out Calculator"
              >
                <h2 className={`${calc === "eto" ? "primary-color" : ""}`}>
                  Refinance and Equity Take Out Calculator{" "}
                  {calc === "eto" && <span>></span>}
                </h2>
                <p>
                  Click here to use RateLock's Refinance and Equity Take Out
                  Calculator
                </p>
              </div>
              <div
                className={`${calc === "mortgage" ? "selected" : ""}`}
                onClick={() => switchCalc("mortgage")}
                onKeyDown={() => switchCalc("mortgage")}
                role="button"
                tabIndex={0}
                aria-label="Mortgage Calculator"
              >
                <h2 className={`${calc === "mortgage" ? "primary-color" : ""}`}>
                  Mortgage Calculator {calc === "mortgage" && <span>></span>}
                </h2>
                <p>
                  Click here to use the RateLock mortgage payment calculator to
                  determine your estimated monthly mortgage payments.
                </p>
              </div>
              <div
                className={`${calc === "affordability" ? "selected" : ""}`}
                onClick={() => switchCalc("affordability")}
                onKeyDown={() => switchCalc("affordability")}
                role="button"
                tabIndex={0}
                aria-label="Affordability Calculator"
              >
                <h2
                  className={`${
                    calc === "affordability" ? "primary-color" : ""
                  }`}
                >
                  Affordability Calculator{" "}
                  {calc === "affordability" && <span>></span>}
                </h2>
                <p>
                  Click here to use RateLock's affordability calculator can help
                  you determine your total expense and estimate your home value
                  to make sure you stay within your budget.
                </p>
              </div>
            </div>
            <div id="calculators-container" className="slide-in-left">
              {calc === "mortgage" && <MortgagePaymentCalculator />}
              {calc === "affordability" && <AffordabilityCalculator />}
              {calc === "eto" && <ETOCalculator />}
              <Link to="/apply-now" className="btn">
                Get Started
              </Link>
            </div>
          </div>
        </section>
      </Layout>
    </PageTransitionContainer>
  )
}

export default Index

export const mortgageCalcValues = {
  purchase: {
    value: 100000,
    label: "Purchase Price",
    type: "amt",
    config: {
      min: 0,
      max: 3000000,
      step: 10000,
      defaultValue: 100000
    }
  },
  downpayment: {
    value: 20000,
    label: "Downpayment",
    type: "amt",
    config: {
      min: 5000,
      max: 3000000,
      step: 1000,
      defaultValue: 20000
    }
  },
  interestRate: {
    value: 3.5,
    label: "Interest Rate",
    type: "perc",
    config: {
      min: 1,
      max: 20,
      step: 0.01,
      defaultValue: 3.5
    }
  },
  paymentFrequency: {
    value: 0,
    label: "Payment Frequency",
    config: {
      marks: { 0: "Monthly", 2: "Semi Monthly", 4: "Bi-Weekly", 6: "Weekly" },
      step: null,
      min: 0,
      max: 6,
      defaultValue: 0
    }
  },
  amortization: {
    value: 20,
    label: "Amortization (Years)",
    type: "years",
    config: {
      min: 10,
      max: 40,
      step: 1,
      defaultValue: 20
    }
  }
}

export const affordabilityCalcValues = {
  annualIncomeBeforeTax: {
    value: 150000,
    label: "Annual Income before taxes",
    type: "amt",
    config: {
      min: 20000,
      max: 500000,
      step: 1000,
      defaultValue: 150000
    }
  },
  downpayment: {
    value: 15000,
    label: "Downpayment",
    type: "amt",
    config: {
      min: 5000,
      max: 3000000,
      step: 1000,
      defaultValue: 15000
    }
  },
  interestRate: {
    value: 3.29,
    label: "Interest Rate",
    type: "perc",
    config: {
      min: 1,
      max: 20,
      step: 0.01,
      defaultValue: 3.29
    }
  },
  amortization: {
    value: 25,
    label: "Amortization (Years)",
    type: "years",
    config: {
      min: 10,
      max: 40,
      step: 1,
      defaultValue: 25
    }
  },
  propertyTax: {
    value: 4000,
    label: "Property Tax (per year)",
    type: "amt",
    config: {
      min: 500,
      max: 20000,
      step: 50,
      defaultValue: 4000
    }
  },
  heat: {
    value: 0,
    label: "Heat (per month)",
    type: "amt",
    config: {
      min: 0,
      max: 1000,
      step: 10
    }
  },
  condoFee: {
    value: 0,
    label: "Condo Fee (per month)",
    type: "amt",
    config: {
      min: 0,
      max: 2000,
      step: 10,
      defaultValue: 0
    }
  },
  loanPayment: {
    value: 0,
    label: "Credit Card and Other Loan Payments (per month)",
    type: "amt",
    config: {
      min: 0,
      max: 10000,
      step: 10,
      defaultValue: 0
    }
  }
}

export const etoCalcValues = {
  propertyValue: {
    value: 600000,
    label: "Your Property Value",
    type: "amt",
    config: {
      min: 100000,
      max: 1000000,
      step: 10000,
      defaultValue: 600000
    }
  },
  currMortgage: {
    value: 170000,
    label: "Current Mortgage Balance",
    type: "amt",
    config: {
      min: 0,
      max: 2000000,
      step: 10000,
      defaultValue: 170000
    }
  }
}

import React, { useState, useEffect } from "react"
import "rc-slider/assets/index.css"
import SliderElement from "./SliderElement"
import { addCommas } from "../../utils/helper"
import { affordabilityCalcValues } from "../../data/calculators"

const AffordabilityCalculator = ({ match }) => {
  const [benchmark, setBenchmark] = useState(0.0504)
  const [benchmark2, setBenchmark2] = useState(0.02)
  const [gds, setGds] = useState(0.39)
  const [tds, setTds] = useState(0.44)

  useEffect(() => {
    fetch(
      `https://spreadsheets.google.com/feeds/cells/1_XKoCUNap3H9ZO0L9_am2S8x_Cdq1FTCQBSf5nbAwRk/1/public/full?alt=json`
    )
      .then(response => response.json())
      .then(data => {
        const entry =
          data && data.feed && data.feed.entry ? data.feed.entry : null
        if (entry) {
          for (const x of entry) {
            if (x.title && x.title["$t"] && x.content && x.content["$t"]) {
              if (x.title["$t"] === "B1") {
                // Benchmark
                setBenchmark(parseFloat(x.content["$t"]))
              }
              if (x.title["$t"] === "B2") {
                // Benchmark 2
                setBenchmark2(parseFloat(x.content["$t"]))
              }
              if (x.title["$t"] === "B3") {
                // GDS
                setGds(parseFloat(x.content["$t"]))
              }
              if (x.title["$t"] === "B4") {
                // TDS
                setTds(parseFloat(x.content["$t"]))
              }
            }
          }
        }
      })
      .catch(error => {
        // console.log("error", error)
      })
  }, [])

  const [vals, setVals] = useState(affordabilityCalcValues)

  // Parameters are rate, total number of periods, payment made each period and future value
  function pv(rate, nper, pmt, fv) {
    let pv_value = 0

    rate = parseFloat(rate)
    nper = parseFloat(nper)
    pmt = parseFloat(pmt)
    fv = parseFloat(fv)
    if (nper === 0) {
      // console.log("Why do you want to test me with zeros?");
      return 0
    }

    if (rate === 0) {
      // Interest rate is 0
      pv_value = -(fv + pmt * nper)
    } else {
      let x = Math.pow(1 + rate, -nper)
      let y = Math.pow(1 + rate, nper)
      pv_value = -(x * (fv * rate - pmt + y * pmt)) / rate
    }

    return pv_value
  }

  let calc = () => {
    let {
      annualIncomeBeforeTax,
      downpayment,
      amortization,
      propertyTax,
      heat,
      condoFee,
      loanPayment,
      interestRate
    } = vals
    // convert to floats
    annualIncomeBeforeTax.value = +annualIncomeBeforeTax.value
    downpayment.value = +downpayment.value
    amortization.value = +amortization.value
    propertyTax.value = +propertyTax.value
    heat.value = +heat.value
    condoFee.value = +condoFee.value
    loanPayment.value = +loanPayment.value
    interestRate.value = +interestRate.value

    let totalExpenseIntermediate =
      propertyTax.value / 12 + heat.value + condoFee.value
    let totalExpense = totalExpenseIntermediate + loanPayment.value
    let intRate = interestRate.value / 100

    // let benchmark = 0.0504,
    // let benchmark = interestRate.value / 100,
    // let benchmark2 = 0.02,
    // let gds = 0.39,
    //   tds = 0.44,
    let qrate =
        intRate + benchmark2 > benchmark ? intRate + benchmark2 : benchmark,
      paymentPerYear = 12,
      compound = 2,
      paymentMAllowed_gds =
        (annualIncomeBeforeTax.value / 12) * gds - totalExpenseIntermediate,
      paymentMAllowed_tds =
        (annualIncomeBeforeTax.value / 12) * tds - totalExpense,
      effInterestRate =
        Math.pow(1 + qrate / compound, compound / paymentPerYear) - 1,
      homeValueGDS =
        pv(
          effInterestRate,
          amortization.value * paymentPerYear,
          -1 * paymentMAllowed_gds,
          0
        ) + downpayment.value,
      homeValueTDS =
        pv(
          effInterestRate,
          amortization.value * paymentPerYear,
          -1 * paymentMAllowed_tds,
          0
        ) + downpayment.value,
      maxHomeValue = homeValueGDS <= homeValueTDS ? homeValueGDS : homeValueTDS

    let homeValue =
      maxHomeValue < 1000000
        ? maxHomeValue
        : downpayment.value / maxHomeValue > 0.2
        ? maxHomeValue
        : 999999

    // console.log({
    //   interestRate,
    //   benchmark,
    //   benchmark2,
    //   gds,
    //   tds,
    //   qrate,
    //   paymentPerYear,
    //   compound,
    //   paymentMAllowed_gds,
    //   paymentMAllowed_tds,
    //   effInterestRate,
    //   homeValueGDS,
    //   homeValueTDS,
    //   maxHomeValue,
    //   homeValue,
    //   totalExpense
    // })

    // console.log("values from spreadsheet", {
    //   benchmark,
    //   benchmark2,
    //   tds,
    //   gds
    // })

    return (
      <div className="results">
        {/* <div className="result">
          <span className="result-label">Monthly Payment Amount</span>
          <span className="result-value">
            ${addCommas(totalExpense.toFixed(2))}
          </span>
        </div> */}
        <div className="result">
          <span className="result-label">Home Value</span>
          <span className="result-value">
            ${addCommas(homeValue.toFixed(2))}
          </span>
        </div>
      </div>
    )
  }

  // console.log('vals', vals);
  return (
    <div>
      <form id="mortgage-payment-calculator">
        {Object.keys(vals).map((item, idx) => (
          <SliderElement
            key={idx}
            itemVals={vals[item]}
            item={item}
            vals={vals}
            setVals={setVals}
          />
        ))}
        {calc()}
      </form>
    </div>
  )
}

export default AffordabilityCalculator
